import { AddToCartButtonLocation, type ProductGroupListDto } from '../../backend.ts';
import { getLangObj } from '../../lang/loadLanguage.ts'
import { createQuery, isServer, keepPreviousData, type CreateQueryResult } from '@tanstack/solid-query';
import { createGetCategoryQueryFn } from '../../CategoryHelper.ts';
import { ErrorBoundary, Show, Suspense, batch, createEffect, createSignal, on, onMount, onCleanup } from 'solid-js';
import { useApiConfig } from '../../ApiConfigContext.tsx';
import "../../less/app.less";
import CategoryListItem from './CategoryListItem.tsx';
import ProductListItemPlaceholder from './ProductListItemPlaceholder.tsx';
import Pagination from './Pagination.tsx';
import ProductListItem from './ProductListItem.tsx';
import "./ProductListPage.less";
import Breadcrumbs from './Breadcrumbs.tsx';
import ProductListSidebar, { defaultFilter, type FilterState } from './ProductListSidebar.tsx';
import { createStore, type SetStoreFunction } from 'solid-js/store';
import FilterListControls from './FilterListControls.tsx';
import { createGetProductGroupsQueryFn } from '../../ProductHelper.ts';
import IconSpinner from "~icons/my/spinner";
import ScrollNotification from './ScrollNotification.tsx';
import { useTracking } from '../../TrackingContext.tsx';
import CategoryRelatedItem from './CategoryRelatedItem.tsx';
import AdminBar from '../AdminBar.tsx';

export const PAGE_SIZE = 32;

export const ProductListPage = (props: {CategorySlug: string, page: number}) => {
    const loc = getLangObj().ProductList;
    const apiConfig = useApiConfig();
    
    const [categorySlug, setCategorySlug] = createSignal(props.CategorySlug);
    const [currentPage, setCurrentPage] = createSignal(Number.isInteger(props.page) && props.page > 0 ? props.page : 1);

    const categoryQuery = createQuery(() => ({
        queryKey: ['category-by-slug', categorySlug()],
        queryFn: createGetCategoryQueryFn(apiConfig),
        reconcile: "ID",
    }));

    const [productCount, setProductCount] = createSignal(categoryQuery.data?.ProductCount ?? 0);
    
    const totalPages = () => Math.ceil(productCount() / PAGE_SIZE);
    const indexPageBegin = () => PAGE_SIZE * (currentPage() - 1);
    const indexPageEnd = () => indexPageBegin() + PAGE_SIZE - 1;
    const indexToShowBegin = () => Math.min(indexPageBegin() + 1, productCount());
    const indexToShowEnd = () => Math.min(indexPageEnd() + 1, productCount());

    const AppendNextPage = () => {
        // TODO: keep products from current page visible
        GoToPage(currentPage() + 1, true);
    }

    const GoToPage = (page: number, scrollIntoView: boolean) => {
        if (1 <= page && page != currentPage() && page <= totalPages()) {
            setCurrentPage(page);
    
            if(scrollIntoView) {
                document.querySelector(".product-list-container-section")?.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    const updateStateFromUrl = () => {
        const path = window.location.pathname.slice(1); // Remove leading slash
        const segments = path.split('/');
        
        let newCategorySlug, newPage;

        if (segments.length === 0 || (segments.length === 1 && segments[0] === '')) {
            // Handle empty path
            newCategorySlug = '';
            newPage = 1;
        } else {
            const lastSegment = segments[segments.length - 1];
            
            if (/^\d+$/.test(lastSegment)) {
                // Last segment consists purely of digits
                newPage = parseInt(lastSegment, 10);
                newCategorySlug = segments.slice(0, -1).join('/');
            } else {
                // Last segment is not purely digits
                newPage = 1;
                newCategorySlug = path;
            }
        }
        
        batch(() => {
            if (newCategorySlug !== categorySlug()) {
                setCategorySlug(newCategorySlug);
                setFilterState(defaultFilter());
            }
            setCurrentPage(newPage);
        });
    };

    createEffect(() => {
        const newPath = currentPage() === 1 
            ? `/${categorySlug()}`
            : (categorySlug() ? `/${categorySlug()}/${currentPage()}` : `/${currentPage()}`);
    
        if (newPath !== window.location.pathname) {
            history.pushState(null, '', newPath);
        }
    });

    onMount(() => {
        const handlePopState = () => {
            updateStateFromUrl();
        };
        window.addEventListener('popstate', handlePopState);
        onCleanup(() => {
            window.removeEventListener('popstate', handlePopState);
        });
    });

    // Filtering
    const [isFilterSidebarVisible, setIsFilterSidebarVisible] = createSignal(false);
    createEffect(() => {
        if (isFilterSidebarVisible()) {
            document.body.classList.add("mobile-filters-visible");
        } else {
            document.body.classList.remove("mobile-filters-visible");
        }
    })

    const [filterState, setFilterState_] = createStore<FilterState>(defaultFilter());
    const setFilterState: SetStoreFunction<FilterState> = (...params: any[]) => {
        batch(() => {
            GoToPage(1, true);
            (setFilterState_ as any)(...params);
        })
    };
    
    const isFilterActive = () => (
        filterState.selectedCategories.length > 0 
        || filterState.selectedAttributes.length > 0 
        || filterState.selectedPriceRange.min != null 
        || filterState.selectedPriceRange.max != null
    );
    
    createEffect(
        on(
            () => categorySlug(), 
            () => {
                if(categoryQuery.data?.PrimarySlug != categorySlug()) {
                    setFilterState(defaultFilter);
                }
            }
        )
    );

    // Products query
    const productsQuery: CreateQueryResult<ProductGroupListDto, Error> = createQuery(() => ({
        queryKey: ["products-in-category", categorySlug(), ({
            limit: indexPageEnd() - indexPageBegin() + 1,
            offset: indexPageBegin(),
            filter: filterState,
        })] as [string, string, { limit: number; offset: number; filter: FilterState; }],
        queryFn: createGetProductGroupsQueryFn(useApiConfig()),
        placeholderData: keepPreviousData,
        reconcile: "ID",
    }));

    // Auto update product count
    createEffect(() => {
        setProductCount(productsQuery.data?.MatchingProductCount ?? categoryQuery.data?.ProductCount ?? 0);
    });

    // UI
    const [isDescriptionExpanded, setIsDescriptionExpanded] = createSignal(false);
    
    return (
    <Show when={categoryQuery.data}>{category => <>
    {
        category().Ancestors.length > 0 && 
        <>
            <Breadcrumbs items={[...category().Ancestors, category()]} />
            <AdminBar Items={[{ID: category().ID, Name: category().Name, Type: "category"}]} />
        </>
    }

    <section class="product-list">
        <div class="section">
            <div class="container">
                {
                    category().PrimarySlug != "" &&
                    <div class="section-header">
                        <h1 class="section-title">{category().Name}</h1>
                        {
                            category().Description.length > 0 && 
                            <div class="section-description">
                                <small>
                                    {
                                        category().Description.length < 190 || isDescriptionExpanded() ? 
                                        category().Description : 
                                        <>
                                            {category().Description.substring(0, 165).trimEnd() + "… "}
                                            <button type="button" class="btn btn-link btn-as-link" onclick={() => setIsDescriptionExpanded(true)}>{loc['Show more']}</button>
                                        </>}
                                </small>
                            </div>
                        }
                    </div>
                }

                <div class="row category-row">
                    {
                        category().Children.map(child => {
                            return (
                                <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 category-wrap">
                                    <CategoryListItem Url={child.Url} Title={child.Name} Count={child.ProductCount} Image={child.Image} ImageHeight={child.ImageHeight} ImageWidth={child.ImageWidth} />
                                </div>
                            )
                        })
                    }
                </div>
                {
                    category().Related.length > 0 &&
                    <div class="row related-category-row">
                        <strong>{loc["You might also need:"]}</strong>
                        {
                            category().Related.map(related => <div class="related-category-wrap"><CategoryRelatedItem Url={related.Url} Title={related.Name} /></div>)
                        }   
                    </div>
                }
            </div>
        </div>

        <div class="section section-padding-top-small product-list-container-section">
            <div class="container">
                <div class="product-list-top">
                    <button 
                        type="button" 
                        onclick={() => setIsFilterSidebarVisible(true)}
                        aria-controls="product-list-sidebar" 
                        class="btn btn-sm btn-bordered mr-2 mobile-filters-button-open">
                            {isFilterActive() ? loc["Change filter"] : loc["Filter"]}
                    </button>
                    
                    <Show when={productsQuery.isLoading || productsQuery.isPlaceholderData}>
                        <div class="mb-2 loading-indicator"><IconSpinner /> {loc["Loading..."]}</div>
                    </Show>
                    <Show when={!productsQuery.isLoading && !productsQuery.isPlaceholderData}>
                        <div classList={{"product-counts": true, "filter-active": isFilterActive()}}>{loc["{0} - {1} out of {2} products"](indexToShowBegin(), indexToShowEnd(), productCount())}</div>
                    </Show>
                    
                    <Show when={isFilterActive()}>
                        <FilterListControls filterState={filterState} setFilterState={setFilterState} isFilterActive={isFilterActive} />
                    </Show>
                    <div class="product-list-top-actions">
                        <Pagination categorySlug={categorySlug()} currentPage={currentPage} totalPages={totalPages} goToPage={GoToPage} />
                    </div>
                </div>

                <div class="product-list-container">
                    <ProductListSidebar isFilterActive={isFilterActive} setIsFilterSidebarVisible={setIsFilterSidebarVisible} filterState={filterState} setFilterState={setFilterState} attributes={category().Attributes} categories={category().Children} productsQuery={productsQuery} categoryProductCount={category().ProductCount} />
                    <div classList={{"product-list-content": true, "isPlaceholderData": productsQuery.isPlaceholderData}}>
                        <Suspense>
                            <Show when={productsQuery.isError || productsQuery.data?.ProductGroups.length == 0}>
                                <div class="empty-product-list">
                                    <Show when={productsQuery.isError}>
                                        <div>{productsQuery.error?.message == "Failed to fetch" ? loc["Failed to fetch"] : productsQuery.error?.message}</div>
                                    </Show>
                                    <Show when={categoryQuery.data?.ProductCount == 0}>
                                        <h4>{loc["This category is empty."]}</h4>
                                        <div>{loc["You can go back to"]} <a href={category().Ancestors[category().Ancestors.length - 1].Url}>{category().Ancestors[category().Ancestors.length - 1].Name}</a>.</div>
                                    </Show>
                                    <Show when={(categoryQuery.data?.ProductCount ?? 0) > 0 && productsQuery.data?.ProductGroups.length == 0}>
                                        <h4>{loc["Filter doesn't match any products."]}</h4>
                                        <div>
                                            {loc["You can modify the filter or"]} <button 
                                                onclick={() => {setFilterState(defaultFilter());}} 
                                                type="button" 
                                                class="btn btn-link btn-as-link">
                                                    {loc["show all products"]}
                                            </button> {loc["in the category"]}.
                                        </div>
                                    </Show>
                                </div>
                            </Show>
                        </Suspense>

                        <div class="product-list-item-row">
                            <ErrorBoundary fallback={(err, reset) => <div>Error: {err.toString()} <button onClick={reset}>Try again</button></div>}>
                                <Suspense fallback={
                                    <>
                                    {Array.from({length: indexPageEnd() - indexPageBegin() + 1}).map(_ => (
                                        <div class="product-list-item-col">
                                            <ProductListItemPlaceholder />
                                        </div>
                                    ))}
                                    </>
                                }>
                                    {productsQuery.data?.ProductGroups.map(productGroup => (
                                        <ErrorBoundary fallback={e => (
                                            <div class="product-list-item-col text-danger" style="padding: 3px; overflow: hidden;">
                                                {loc["Failed to load this product. Please contact us."]} {productGroup.ID}<br />
                                                <pre class="text-normal" style="font-size: 6px; padding: 3px; overflow: hidden;">
                                                {e.stack}
                                                </pre>
                                            </div>
                                            )
                                        }>
                                            <div class="product-list-item-col">
                                                <ProductListItem productGroup={productGroup} location={AddToCartButtonLocation.ProductListPage} filteredAttributes={filterState.selectedAttributes} />
                                            </div>
                                        </ErrorBoundary>
                                    ))}
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                        
                        <div class="product-list-bottom">
                            <Show when={productsQuery.isLoading || productsQuery.isPlaceholderData}>
                                <div class="loading-indicator"><IconSpinner /> {loc["Loading..."]}</div>
                            </Show>

                            <Show when={!productsQuery.isLoading && !productsQuery.isPlaceholderData && productsQuery.isSuccess}>
                                <div class="product-list-progressbar">
                                    
                                    
                                        {loc["You've seen {0} out of {1} products"](Math.min(productCount(), indexPageEnd() + 1), productCount())}

                                        <div class="product-list-progressbar-tack">
                                            <div class="product-list-progressbar-track-active" style={{width: `${(100*Math.min(1,(indexPageEnd() + 1)/Math.max(1, productCount())))}%`}}></div>
                                        </div>
                                </div>
                            </Show>

                            <div class="product-list-bottom-right">
                                <button class="btn btn-primary" disabled={currentPage() >= totalPages()} onclick={AppendNextPage}>{loc["Show more"]}</button>
                                <Pagination categorySlug={categorySlug()} currentPage={currentPage} totalPages={totalPages} goToPage={GoToPage} />
                            </div>

                            <ScrollNotification observerSelector=".product-list-item-row">
                                {loc["See {0} products above ↑"](productsQuery.data?.MatchingProductCount)}
                            </ScrollNotification>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* TODO recommendations <Frontflip.Client.Components.ProductDetail.Recommendations Context="Shared.Experiments.ExperimentAssignments.Contexts.InProductList" /> */}
    </>}
    </Show>
    );
}

export default ProductListPage;